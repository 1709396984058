import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

import { mediaUp } from '@/styles/media'

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

export const Wrapper = styled.section`
  position: relative;
  z-index: 1;
`

export const Container = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  top: 0;
`

export const StyledParkingList = styled.div`
  display: grid;
  margin-top: 24px;
  width: 100%;
`

export const SearchWrapper = styled.div`
  background: linear-gradient(to bottom, #093769 50%, #fff 50%);

  ${mediaUp('md')} {
    padding: 0 16px;
    background: linear-gradient(to bottom, #f2f6fd 50%, #fff 50%);
  }
  ${mediaUp('lg')} {
    padding: unset;
  }
`

export const ContainerForResults = styled.div`
  display: grid;
  margin: 0 auto;
  justify-content: center;
  width: 340px;
  gap: 20px;
  margin-bottom: 32px;
  ${mediaUp('md')} {
    display: grid;
    margin-top: 32px;
    max-width: 1440px;
    width: unset;
    grid-template-columns: 230px 3fr;
    padding: 0 16px;
  }
  ${mediaUp('lg')} {
    grid-template-columns: 1fr 3fr;
  }
`

export const ParkingListContainer = styled.div`
  flex: 1;
  padding: 20px;
  max-height: 100%;
`

export const StyledParkingCnt = styled.div`
  display: grid;
  gap: 6px;
  background: #fff;
  width: 320px;
  ${mediaUp('md')} {
    display: flex;
    width: unset;
    flex-direction: column;
  }
`

export const SortWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
`

export const Stroker = styled.div`
  width: 1px;
  background-color: #c1c5c9;
  height: 36px;
`

export const Overlay = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  flex-direction: column;
  z-index: 2000;
  animation: ${({ isVisible }) => (isVisible ? fadeIn : fadeOut)} 0.3s linear;
`

export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  cursor: pointer;
`

export const OverlayContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
`

export const SpinnerOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`
