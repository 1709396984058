import React from 'react'

import { useTranslation } from 'next-i18next'

import airportIcon from '@/assets/search-engine/park-and-fly.svg'
import locationIcon from '@/assets/search-engine/location.svg'
import clockIcon from '@/assets/search-engine/clock.svg'
import deleteIcon from '@/assets/search-engine/delete-recent-search.svg'


import {
  SearchInput,
  RecentSearchesWrapper,
  RecentSearchesTitle,
  ClearHistoryButton,
  SearchItemsWrapper,
  SearchItem,
  Divider
} from './Options.styled'

enum IconType {
  AIRPORT = 'AIRPORT',
  LOCATION = 'LOCATION',
  RECENT_SEARCH = 'RECENT_SEARCH'
}

const getIconByType = (type: IconType) => {
  switch (type) {
    case IconType.AIRPORT:
      return airportIcon
    case IconType.LOCATION:
      return locationIcon
    case IconType.RECENT_SEARCH:
      return clockIcon
    default:
      return locationIcon
  }
}

interface SearchResult {
  id: string
  label: string
  value: [number, number]
  isAirport: boolean
  isRecentSearch?: boolean
}

interface SearchContentProps {
  settedValue: string
  setSettedValue: (value: string) => void
  isOpen: boolean
  filteredRecentSearches: SearchResult[]
  recentSearches: SearchResult[]
  options: SearchResult[]
  handleSelect: (option: SearchResult) => void
  removeRecentSearch: (id: string) => void
  clearHistory: () => void
}

const SearchContent: React.FC<SearchContentProps> = ({
  settedValue,
  setSettedValue,
  isOpen,
  filteredRecentSearches,
  recentSearches,
  options,
  handleSelect,
  removeRecentSearch,
  clearHistory
}) => {
  const { t } = useTranslation('parkings')
  return (
    <>
      <SearchInput
        type="text"
        value={settedValue}
        onChange={e => setSettedValue(e.target.value)}
        placeholder={t('searchNearParking.search.placeholder')}
      />
      {settedValue === '' && recentSearches.length > 0 && (
        <>
          <RecentSearchesWrapper>
            <RecentSearchesTitle>
              {t('searchNearParking.search.recentSearches')}
            </RecentSearchesTitle>
            <ClearHistoryButton onClick={clearHistory}>
              {t('searchNearParking.search.clearHistory')}
            </ClearHistoryButton>
          </RecentSearchesWrapper>
          <SearchItemsWrapper>
            {recentSearches.map((item: SearchResult, index: number) => (
              <SearchItem key={index} onClick={() => handleSelect(item)}>
                <img
                  src={getIconByType(IconType.RECENT_SEARCH)}
                  alt="recent search icon"
                />
                <span>{item.label}</span>
                <button onClick={() => removeRecentSearch(item.id)}>
                  <img src={deleteIcon} alt="delete icon" />
                </button>
              </SearchItem>
            ))}
          </SearchItemsWrapper>
        </>
      )}
      {isOpen && settedValue !== '' && (
        <>
          {filteredRecentSearches.length > 0 && (
            <>
              <SearchItemsWrapper>
                {filteredRecentSearches.map(
                  (item: SearchResult, index: number) => (
                    <SearchItem key={index} onClick={() => handleSelect(item)}>
                      <img
                        src={getIconByType(IconType.RECENT_SEARCH)}
                        alt="recent search icon"
                      />
                      <span>{item.label}</span>
                      <button onClick={() => removeRecentSearch(item.id)}>
                        <img src={deleteIcon} alt="delete icon" />
                      </button>
                    </SearchItem>
                  )
                )}
              </SearchItemsWrapper>
              <Divider />
            </>
          )}
          <SearchItemsWrapper>
            {options.map((item: SearchResult, index: number) => (
              <SearchItem key={index} onClick={() => handleSelect(item)}>
                <img
                  src={getIconByType(
                    item.isAirport ? IconType.AIRPORT : IconType.LOCATION
                  )}
                  alt="location icon"
                />
                <span>{item.label}</span>
                {!item.isRecentSearch && (
                  <button style={{ visibility: 'hidden' }}>X</button>
                )}
              </SearchItem>
            ))}
          </SearchItemsWrapper>
        </>
      )}
    </>
  )
}

export default SearchContent
